import Vue from "vue";
import VueYandexMetrika from "vue-yandex-metrika";
import router from "../router/router";
import Config from "./Config";

Vue.use(VueYandexMetrika, {
  env: process.env.NODE_ENV || "development",
  id: Config.value("respondentYandexMetricaId"),
  scriptSrc: Config.value("yandexMetricaScriptSrc"),
  // debug: process.env.NODE_ENV === "development",
  router: router,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    trackHash: true
  }
});
