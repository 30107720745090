<template>
  <div id="app" class="d-flex h-100 flex-column">
    <EmotivaHeader></EmotivaHeader>
    <transition :name="transitionName">
      <router-view class="h-100"> </router-view>
    </transition>
  </div>
</template>

<script>
import EmotivaHeader from "./components/EmotivaHeader.vue";
export default {
  components: {
    EmotivaHeader,
  },
  data() {
    return {
      transitionName: "fade",
    };
  },

  created() {},
};
</script>

<style lang="scss">
@import "./assets/custom-bootstrap-vars.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
</style>

<style>
html,
body {
  height: 100%;
  background-color: var(--gray);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.emotiva__logo {
  width: 120px;
}
</style>
