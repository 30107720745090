const defaultState = () => {
  return {
    environment: "test",
    dynataFields: {
      pid: "",
      k2: "",
      psid: "",
    },
    stimulusToken: "",
    webcamSetup: false,
  };
};

const state = defaultState();

const getters = {
  getEnvironment: (state) => state.environment,
  getDynataFields: (state) => state.dynataFields,
  getStimulusToken: (state) => state.stimulusToken,
  getRespondentId: (state) => state.dynataFields.pid,
  getWebcamSetup: (state) => state.webcamSetup,
};

const mutations = {
  RESET_STATE: (state) => {
    const s = defaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_ENVIRONMENT: (state, environment) => (state.environment = environment),
  SET_DYNATA_FIELDS: (state, dynataFields) =>
    (state.dynataFields = dynataFields),
  SET_STIMULUS_TOKEN: (state, stimulusToken) =>
    (state.stimulusToken = stimulusToken),
  SET_WEBCAM_SETUP: (state, webcamSetup) => (state.webcamSetup = webcamSetup),
};

const actions = {};

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state,
  getters,
  mutations,
  actions,
};
