const defaultState = () => {
  return {
    showEmotivaHeader: true,
  };
};

const state = defaultState();

const getters = {
  getEmotivaHeader: (state) => state.showEmotivaHeader,
};

const mutations = {
  RESET_STATE: (state) => {
    const s = defaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_EMOTIVA_HEADER: (state, showEmotivaHeader) =>
    (state.showEmotivaHeader = showEmotivaHeader),
};

const actions = {};

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state,
  getters,
  mutations,
  actions,
};
