import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {
      name: "entry",
      params: {
        pathMatch: "test",
        stimulusToken: 1234,
      },
      query: {
        pid: 1234,
        k2: 1234,
        psid: 1234,
      },
    },
  },
  {
    path: "/*/permission-check",
    name: "permission",
    component: () => import("../views/Permission.vue"),
    meta: {
      title: "Permission Check",
    },
    // beforeEnter(to, from, next) {
    //   const formCompleted = store.getters["stimulus/isFormCompleted"];
    //   console.log("FORM COMPLETED", formCompleted);
    //   if (formCompleted) {
    //     next({
    //       name: "viewer",
    //       params: { pathMatch: store.getters["settings/getEnvironment"] }
    //     });
    //   } else {
    //     next();
    //   }
    // }
  },
  {
    path: "/*/welcome",
    name: "welcome",
    meta: {
      title: "Welcome",
    },
    component: () => import("../views/Welcome.vue"),
    // beforeEnter(to, from, next) {
    //   const formCompleted = store.getters["stimulus/isFormCompleted"];
    //   console.log("FORM COMPLETED", formCompleted);
    //   if (formCompleted) {
    //     next({
    //       name: "viewer",
    //       params: { pathMatch: store.getters["settings/getEnvironment"] }
    //     });
    //   } else {
    //     next();
    //   }
    // }
  },
  {
    path: "/*/setup",
    name: "setup",
    meta: {
      title: "Setup",
    },
    component: () => import("../views/Setup.vue"),
    children: [
      {
        path: "/*/video",
        name: "standard",
        meta: {
          title: "Video",
        },
        component: () => import("../views/Video.vue"),
      },
      {
        path: "/*/facebook",
        name: "facebook",
        meta: {
          title: "Facebook",
        },
        component: () => import("../views/Facebook.vue"),
      },
      {
        path: "/*/youtube",
        name: "youtube",
        meta: {
          title: "YouTube",
        },
        component: () => import("../views/Youtube.vue"),
      },
      {
        path: "/*/instagram",
        name: "instagram",
        meta: {
          title: "Instagram",
        },
        component: () => import("../views/Instagram.vue"),
      },
      {
        path: "/*/tiktok",
        name: "tiktok",
        meta: {
          title: "Tiktok",
        },
        component: () => import("../views/TiktokMobile.vue"),
      },
    ],
    // beforeEnter(to, from, next) {
    //   const formCompleted = store.getters["stimulus/isFormCompleted"];
    //   console.log("FORM COMPLETED", formCompleted);
    //   if (formCompleted) {
    //     next({
    //       name: "viewer",
    //       params: { pathMatch: store.getters["settings/getEnvironment"] }
    //     });
    //   } else {
    //     next();
    //   }
    // }
  },
  {
    path: "/*/questions",
    name: "questions",
    meta: {
      title: "Questions",
    },
    component: () => import("../views/Questions.vue"),
    // beforeEnter(to, from, next) {
    //   const done = store.getters["stimulus/isDone"];
    //   if (done) {
    //     next({
    //       name: "done",
    //       params: { pathMatch: store.getters["settings/getEnvironment"] }
    //     });
    //   } else {
    //     next();
    //   }
    // }
  },
  {
    path: "/*/done",
    name: "done",
    meta: {
      title: "Done",
    },
    component: () => import("../views/Done.vue"),
  },
  {
    path: "/error",
    name: "error",
    meta: {
      title: "Error",
    },
    component: () => import("../views/ErrorPage.vue"),
  },
  {
    path: "/*/:stimulusToken",
    name: "entry",
    meta: {
      title: "Entry",
    },
    beforeEnter(to, from, next) {
      console.log(to);
      const pathMatch = to.params.pathMatch;
      const dynataFields = to.query;
      const stimulusToken = to.params.stimulusToken;
      if (
        ((pathMatch === "live" || pathMatch === "test") &&
          dynataFields.pid &&
          dynataFields.k2 &&
          dynataFields.psid) ||
        (dynataFields.bsec && dynataFields.transaction_id)
      ) {
        if (dynataFields.bsec && dynataFields.transaction_id) {
          // match dynata's
          dynataFields.pid = dynataFields.transaction_id;
          dynataFields.k2 = dynataFields.bsec;
          dynataFields.psid = dynataFields.transaction_id;
          delete dynataFields.transaction_id;
          delete dynataFields.bsec;
        }
        store.commit("stimulus/RESET_STATE");
        store.commit("settings/RESET_STATE");

        store.commit("settings/SET_ENVIRONMENT", pathMatch);
        store.commit("settings/SET_DYNATA_FIELDS", dynataFields);
        store.commit("settings/SET_STIMULUS_TOKEN", stimulusToken);
        store
          .dispatch("stimulus/getStimulus", { stimulusToken })
          .then(() => {
            const endLinks = store.getters["stimulus/getEndLinks"];
            if (endLinks.overquota) window.location = endLinks.overquota;
            else next({ name: "permission", params: { pathMatch } });
          })
          .catch(() => {
            next({ name: "error" });
          });
      } else {
        next({ name: "error" });
      }
    },
  },
  {
    path: "*",
    redirect: { name: "error" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("loader/close");

  document.title = "Respondent Viewer | " + to.meta.title;
  const stimulusToken = store.getters["settings/getStimulusToken"];
  if (stimulusToken === "" && to.name !== "entry" && to.name !== "error") {
    console.log("Not stimulus token");
    next({ name: "error" });
  } else {
    next();
  }
});

export default router;
