import Vue from "vue";
import VueI18n from "vue-i18n";
import Config from "./plugins/Config";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      // get locale from complex filename (filename 'locale-dataName')
      const formattedLocale = locale.split("-")[0];

      // concat all translations from all pages
      messages[formattedLocale] = {
        ...messages[formattedLocale],
        ...locales(key)
      };
    }
  });
  return messages;
}

export default new VueI18n({
  locale: navigator.language.startsWith("it") ? "it" : "en",
  fallbackLocale: Config.value("i18nFallbackLocale") || "en",
  messages: loadLocaleMessages()
});
