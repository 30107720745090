import Vue from "vue";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import { required, min, integer } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

/** LOCALIZE */
localize("en", en);

/** CONFIG EACH RULE */
extend("required", {
  ...required,
  message: (fieldName) => `${fieldName} is required.`,
});

extend("min", {
  ...min,
});

extend("integer", {
  ...integer,
  message: (fieldName) => `${fieldName} must be an integer.`,
});

/** CUSTOM RULES */
extend("age", {
  message: (fieldName) => `${fieldName} is not valid.`,
  validate(value) {
    const amount = Number(value);
    return !isNaN(amount) && amount >= 18 && amount < 100;
  },
});
