import SocialsService from "../services/SocialsService";

const defaultState = () => {
  return {
    limit: 1,
    offset: 0,
    isLoadingPosts: false,
    posts: [],
    surveyStepDuration: 90,
    surveyStep: "scroll",
    showPrompt: true,
    stimulusIsLiked:false,
  };
};

const state = defaultState();

const getters = {
  getPosts: (state) => state.posts,
  getSurveyStep: (state) => state.surveyStep,
  getSurveyStepDuration: (state) => state.surveyStepDuration,
  getShowPrompt: (state) => state.showPrompt,
  getStimulusIsLiked:(state)=>state.stimulusIsLiked,
  
};

const mutations = {
  RESET_STATE: (state) => {
    const s = defaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_SURVEY_STEP: (state, surveyStep) => (state.surveyStep = surveyStep),
  SET_SURVEY_STEP_DURATION: (state, surveyStepDuration) =>
    (state.surveyStepDuration = surveyStepDuration),
  SET_SHOW_PROMPT: (state, showPrompt) => (state.showPrompt = showPrompt),

  SET_OFFSET: (state, offset) => (state.offset = offset),
  ADD_POSTS: (state, posts) => state.posts.push(...posts),
  SET_IS_LOADING_POSTS: (state, loading) => (state.isLoadingPosts = loading),
  SET_POST_IS_VIEWED: (state, id) => {
    state.posts[id].isViewed = true;
  },
  SET_POST_IS_LIKED: (state, id) => {
    state.posts[id].isLiked = !state.posts[id].isLiked;
    if(state.posts[id].isStimulus){
      state.posts[id].isLiked
      ? --state.posts[id].bodyParsed.footer.reactionsCounter
      : ++state.posts[id].bodyParsed.footer.reactionsCounter;
      state.stimulusIsLiked = !state.stimulusIsLiked;
    }
  },
  
  
};

const actions = {
  getPosts({ state, commit, rootGetters }) {
    // prevent duplicate requests from infinite scrolling
    if (state.isLoadingPosts) return;
    commit("SET_IS_LOADING_POSTS", true);
    return SocialsService.retrievePosts({
      env: rootGetters["settings/getEnvironment"],
      limit: state.posts.length === 0 ? 2 : state.limit,
      skip: state.offset,
      source: rootGetters["stimulus/getViewer"],
    })
      .then((posts) => {
        let customPosts = posts;
        // manipulate posts before saving to store
        customPosts = posts.map((post) => {
          post.bodyParsed = JSON.parse(post.body);
          post.isViewed = false;
          post.isLiked = false;
          post.isShared = false;
          return post;
        });
        console.log("le posts: ",customPosts)
        // create and inject the stimulus post to first posts request
        if (state.posts.length === 0) {
          const stimulusSrc = rootGetters["stimulus/getStimulus"][0].src;
          const stimulusPost = generateStimulusPost(stimulusSrc);
          customPosts.splice(1, 0, stimulusPost);
        }
        commit("ADD_POSTS", customPosts);
        commit("SET_OFFSET", state.posts.length);
      })
      .catch((error) => {
        return Promise.reject(error);
      })
      .finally(() => {
        commit("SET_IS_LOADING_POSTS", false);
      });
  },
};

const generateStimulusPost = (stimulusSrc) => {
  return {
    bodyParsed: {
      header: {
        leftColumn: {
          imageUrl:
            "",
          innerImgUrl: "",
        },
        rightColumn: {
          topRow: {
            title: "",
            verifiedAccount: "",
          },
          bottomRow: {
            posterName: "", // Ad
            postedSince: "",
            sponsored: false,
            public: false,
          },
        },
      },
      body: {
        topRow: {
          text: "", // Ad text
        },
        bottomRow: {
          contentType: "",
          image: {
            url: "https://media-cdn.incontext-research.com/facebook/users/102099916530784/posts/102099916530784_9044993432241343/image-src/image.jpeg",
          },
          video: {
            imageUrl: "",
            streamOriginalUrl: "",
            streamModifiedUrl: stimulusSrc,
          },
          link: {
            externalUrl: "",
            imageUrl: "",
            text: {
              domain: "",
              title: "",
              subtitle: "",
            },
          },
        },
      },
      footer: {
        reactionsCounter: Math.floor(Math.random() * 1000),
        commentsCounter: Math.floor(Math.random() * 1000),
        sharesCounter: Math.floor(Math.random() * 1000),
      },
    },
    isViewed: false,
    isLiked: false,
    comments: [],
    pictures: [],
    isStimulus: true,
  };
};

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state,
  getters,
  mutations,
  actions,
};
