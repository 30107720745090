<template>
  <div v-if="getEmotivaHeader">
    <b-navbar style="height: 70px" variant="blue">
      <b-navbar-brand>
        <img
          class="emotiva__logo"
          src="../assets/img/Logo-Emotiva-2020.svg"
          alt="logo"
        />
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto font-weight-light">
        <div class="px-md-2">
          <base-locale-switcher />
        </div>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import BaseLocaleSwitcher from "./BaseLocaleSwitcher.vue";
export default {
  components: { BaseLocaleSwitcher },
  computed: {
    getEmotivaHeader() {
      return this.$store.getters["utilities/getEmotivaHeader"];
    },
  },
};
</script>
