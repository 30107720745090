import SocialsService from "../services/SocialsService";

const defaultState = () => {
  return {
    limit: 3,
    offset: 0,
    posts: [],
    isLoadingPosts: false,
    scrollPositions: [],
    surveyStep: "scroll",
    surveyStepDuration: 45,
    showPrompt: true,
    stimulusIsLiked:false,
  };
};

const state = defaultState();

const getters = {
  getPosts: (state) => state.posts,
  getSurveyStep: (state) => state.surveyStep,
  getSurveyStepDuration: (state) => state.surveyStepDuration,
  getShowPrompt: (state) => state.showPrompt,
};

const mutations = {
  RESET_STATE: (state) => {
    const s = defaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_OFFSET: (state, offset) => (state.offset = offset),
  ADD_POSTS: (state, posts) => state.posts.push(...posts),
  SET_IS_LOADING_POSTS: (state, loading) => (state.isLoadingPosts = loading),
  SET_SCROLL_POSITION: (state, position) =>
    state.scrollPositions.push(position),
  SET_SURVEY_STEP: (state, surveyStep) => (state.surveyStep = surveyStep),
  SET_SURVEY_STEP_DURATION: (state, surveyStepDuration) =>
    (state.surveyStepDuration = surveyStepDuration),
  SET_SHOW_PROMPT: (state, showPrompt) => (state.showPrompt = showPrompt),
  SET_PLAY_STIMULUS: (state, playStimulus) =>
    (state.playStimulus = playStimulus),
  SET_POST_IS_LIKED: (state, id) => {
    state.posts[id].isLiked
      ? --state.posts[id].bodyParsed.footer.reactionsCounter
      : ++state.posts[id].bodyParsed.footer.reactionsCounter;
    state.posts[id].isLiked = !state.posts[id].isLiked;
  },
  SET_POST_COMMENT: (state, { id, comment }) => {
    state.posts[id].comments.push(comment);
    ++state.posts[id].bodyParsed.footer.commentsCounter;
  },
  SET_POST_IS_VIEWED: (state, id) => {
    state.posts[id].isViewed = true;
  },
  SET_STIMULUS_IS_LIKED:(state)=>{
    state.stimulusIsLiked=!state.stimulusIsLiked;
  }
};

const actions = {
  getPosts({ state, commit, rootGetters }) {
    // prevent duplicate requests from infinite scrolling
    if (state.isLoadingPosts) return;
    commit("SET_IS_LOADING_POSTS", true);
    // console.log("Retrieving Posts");
    SocialsService.retrievePosts({
      env: rootGetters["settings/getEnvironment"],
      limit: state.posts.length === 0 ? 2 : state.limit,
      skip: state.offset,
      source: "facebook",
    })
      .then((posts) => {
        let customPosts = posts;
        // customize posts as needed for each platform
        if (posts) {
          // customize facebook posts
          customPosts = posts.map((post) => {
            post.bodyParsed = JSON.parse(post.body);
            post.profilePic = post.pictures.find(((string)=>string.includes("profilePic")));          
            post.pictures.splice(post.pictures.indexOf(post.profilePic),1);

            post.bodyParsed.footer = {
              reactionsCounter: Math.floor(Math.random() * 100),
              commentsCounter: Math.floor(Math.random() * 100),
              sharesCounter: Math.floor(Math.random() * 100),
              showHeartEmoji: Math.random() < 0.2,
              showLaughEmoji: Math.random() < 0.2,
              showSurpriseEmoji: Math.random() < 0.2,
              showSadEmoji: Math.random() < 0.2,
            };
            post.isViewed = false;
            post.isLiked = false;
            post.isFollowed = false;
            post.comments = [];
            return post;
          });
          // create and inject the stimulus post to first posts request
          if (state.posts.length === 0) {
            const stimulusSrc = rootGetters["stimulus/getStimulus"][0].src;
            const stimulus = generateStimulusPost(stimulusSrc);
            customPosts.splice(1, 0, stimulus);
          }
        }
        commit("ADD_POSTS", customPosts);
        commit("SET_OFFSET", state.posts.length);
      })
      .catch((error) => {
        return Promise.reject(error);
      })
      .finally(() => {
        commit("SET_IS_LOADING_POSTS", false);
      });
  },
};

const generateStimulusPost = (stimulusSrc) => {
  return {
    bodyParsed: {
      header: {
        leftColumn: {
          imageUrl:
            "",
          innerImgUrl: "",
        },
        rightColumn: {
          topRow: {
            title: "",
            verifiedAccount: "false",
          },
          bottomRow: {
            posterName: "",
            postedSince: "Sponsored",
            sponsored: false,
            public: false,
          },
        },
      },
      body: {
        topRow: {
          text: [],
        },
        bottomRow: {
          contentType: "video",
          image: {
            url: "https://media-cdn.incontext-research.com/facebook/users/102099916530784/posts/102099916530784_9044993432241343/image-src/image.jpeg",
          },
          video: {
            imageUrl: "",
            streamOriginalUrl: "",
            streamModifiedUrl: stimulusSrc,
          },
          link: {
            externalUrl: "",
            imageUrl: "",
            text: {
              domain: "",
              title: "",
              subtitle: "",
            },
          },
        },
      },
      footer: {
        reactionsCounter: Math.floor(Math.random() * 100),
        commentsCounter: Math.floor(Math.random() * 100),
        sharesCounter: Math.floor(Math.random() * 100),
        showHeartEmoji: Math.random() < 0.2,
        showLaughEmoji: Math.random() < 0.2,
        showSurpriseEmoji: Math.random() < 0.2,
        showSadEmoji: Math.random() < 0.2,
      },
    },
    isViewed: false,
    isLiked: false,
    comments: [],
  };
};

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state,
  getters,
  mutations,
  actions,
};
