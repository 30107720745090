import swaggerClient from "../plugins/swagger";

export default {
  retrievePosts(parameters) {
    return swaggerClient.emotivaClient
      .then(
        (client) => {
          return client.apis.posts.retrievePosts(
            parameters,
            swaggerClient.buildRequest(),
            {}
          );
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .then((response) => {
        return response.body;
      })
      .catch((reason) => {
        console.log("ERROR: ", reason);
      });
  },
};
