import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router/router";

import "./plugins/bootstrap-vue";
import "./plugins/vee-validation";
import "./plugins/yandex-metrica";

import VueGtag from "vue-gtag";

import i18n from "./i18n";
import Config from "./plugins/Config";
import Vue2TouchEvents from "vue2-touch-events";

Vue.use(
  VueGtag,
  {
    config: {
      id: Config.value("respondentGoogleAnalyticsId"),
      // params: {
      //   send_page_view: false
      // }
    },
  },
  router
);

Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: "",
  tapTolerance: 10,
  touchHoldTolerance: 400,
  swipeTolerance: 30,
  longTapTimeInterval: 400,
  namespace: "touch",
});

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
