import StimulusService from "../services/StimulusService";

const defaultState = () => {
  return {
    stimulus: {},
    permission: false,
    gender: "",
    age: "",
    formCompleted: false,
    done: false,
    uploaded: false,
    images: [],
    answers: [],
    completeLink: "",
    uploadImagesCompleted: false,
    uploadAnswersCompleted: false,
    socialsFirstSurveyStepImgCount: 0,
    liked: false,
  };
};

const state = defaultState();

const getters = {
  getStimulus: (state) => state.stimulus.stimulus,
  getViewer: (state) => state.stimulus.viewer,
  getPermission: (state) => state.permission,
  getAge: (state) => state.age,
  getGender: (state) => state.gender,
  getImages: (state) => state.images,
  isFormCompleted: (state) => state.formCompleted,
  isDone: (state) => state.done,
  getQuestions: (state) => state.stimulus.questions,
  getEndLinks: (state) => state.stimulus.endLinks,
  getAnswers: (state) => state.answers,
  getCompleteLink: (state) => state.completeLink,
  isUploadImagesCompleted: (state) => state.uploadImagesCompleted,
  isUploadAnswersCompleted: (state) => state.uploadAnswersCompleted,
  isliked: (state) => state.liked,
};

const mutations = {
  RESET_STATE: (state) => {
    const s = defaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_STIMULUS: (state, data) => (state.stimulus = data),
  SET_DONE: (state, done) => (state.done = done),
  SET_PERMISSION: (state, permission) => (state.permission = permission),
  SET_GENDER: (state, gender) => (state.gender = gender),
  SET_AGE: (state, age) => (state.age = age),
  SET_IMAGES: (state, data) => (state.images = data),
  SET_FORM_COMPLETED: (state, completed) => (state.formCompleted = completed),
  SET_ANSWERS: (state, answers) => (state.answers = answers),
  SET_COMPLETE_LINK: (state, completeLink) =>
    (state.completeLink = completeLink),
  SET_UPLOAD_IMAGES_COMPLETED: (state, isUploaded) =>
    (state.uploadImagesCompleted = isUploaded),
  SET_UPLOAD_ANSWERS_COMPLETED: (state, isUploaded) =>
    (state.uploadAnswersCompleted = isUploaded),
  SET_STIMULUS_VIEWER: (state, viewer) => (state.stimulus.viewer = viewer),
  SET_SOCIALS_FIRST_SURVEY_STEP_IMAGE_COUNT: (state, count) =>
    (state.socialsFirstSurveyStepImgCount = count),
  SET_STIMULUS_LIKED: (state) => {
    state.liked = !state.liked;
  },
};

const actions = {
  getStimulus({ commit, rootGetters }, { stimulusToken }) {
    const dynataFields = rootGetters["settings/getDynataFields"];
    return StimulusService.retrieveStimulus({
      stimulusToken,
      respondentId: dynataFields.pid,
      psid: dynataFields.psid,
      k2: dynataFields.k2,
      type: dynataFields.type,
    })
      .then((stimulus) => {
        const stimulusVideoList = stimulus.stimulus.map((s) => ({
          ...s,
          src: s.src.replace(/\+/g, "%2B"),
        }));
        stimulus.stimulus = stimulusVideoList;
        commit("SET_STIMULUS", stimulus);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  updateProgress({ commit }, { stimulusToken, tick, respondentId }) {
    StimulusService.updateProgress(stimulusToken, { respondentId, tick })
      .then(() => {
        console.log("Tick", tick);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  addStimulusImages({ state, commit }, { stimulusToken, images }) {
    const stimulus = state.stimulus.stimulus.map((s) => {
      if (s.stimulusId === stimulusToken) {
        if (s.images) {
          return { ...s, images: [...s.images, ...images] };
        } else {
          return { ...s, images };
        }
      }
      return s;
    });
    commit("SET_STIMULUS", { ...state.stimulus, stimulus });
  },

  setSocialsFirstSurveyStepImgCount({ state, commit }) {
    const imageCount = state.stimulus.stimulus[0].images.length;
    commit("SET_SOCIALS_FIRST_SURVEY_STEP_IMAGE_COUNT", imageCount);
  },

  resetStimulusImages({ state, commit }, { stimulusToken }) {
    const stimulus = state.stimulus.stimulus.map((s) => {
      if (s.stimulusId === stimulusToken) {
        return { ...s, images: [] };
      }
      return s;
    });
    commit("SET_STIMULUS", { ...state.stimulus, stimulus });
  },

  uploadImages({ rootState }, { stimulusToken, images, respondentId }) {
    // if (rootState.settings.environment === "live") {
    return StimulusService.uploadImages(stimulusToken, respondentId, images)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    // } //  else environment === "test", no actions here
  },

  uploadImagesV2({ state }, { stimulusToken, images, respondentId }) {
    // if (rootState.settings.environment === "live") {
    return StimulusService.uploadImagesV2(
      stimulusToken,
      respondentId,
      images,
      state.socialsFirstSurveyStepImgCount
    )
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    // } //  else environment === "test", no actions here
  },

  uploadAnswers({ getters, rootGetters }, params = {}) {
    const storedStimulusToken = rootGetters["settings/getStimulusToken"];
    const respondentId = rootGetters["settings/getRespondentId"];
    const projectAnswers = getters.getAnswers;
    const stimulusToken = params.stimulusToken || storedStimulusToken;
    let answers = [];

    if (projectAnswers.length > 0) {
      answers = projectAnswers.map((answerInfo) => {
        if (Array.isArray(answerInfo.answer)) {
          return answerInfo;
        } else {
          const valueAsArray = [answerInfo.answer];
          return {
            _id: answerInfo.id,
            answer: valueAsArray,
          };
        }
      });
    }
    answers.push({
      _id: "gender",
      answer: [getters.getGender],
    });
    answers.push({
      _id: "age",
      answer: [getters.getAge],
    });
    answers.push({
      liked: getters.isliked,
    });
    console.log(answers);
    return StimulusService.uploadAnswers(stimulusToken, {
      respondentId,
      answers,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state,
  getters,
  mutations,
  actions,
};
