import SwaggerClient from "swagger-client";
import Config from "../plugins/Config";

import i18n from "../i18n";

const emotivaUrl = "/emotiva.v1.json";
const proxyURL = Config.value("backendHost");
console.log(proxyURL);

const buildRequest = function(requestBody) {
  return {
    requestBody: requestBody,
    server: "{protocol}://{host}:{port}{basePath}{apiPrefix}",
    serverVariables: {
      protocol: Config.value("backendProtocol"),
      host: Config.value("backendHost"),
      port: Config.value("backendPort"),
      basePath: Config.value("backendBasePath"),
      apiPrefix: Config.value("backendPrefix")
    }
  };
};

const emotivaClient = new SwaggerClient(emotivaUrl, {
  requestInterceptor: request => {
    request.headers["accept-languange"] = i18n.locale;
    return request;
  },
  responseInterceptor: () => {}
});

export default {
  buildRequest,
  emotivaClient
};
