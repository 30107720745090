import SocialsService from "../services/SocialsService";

const defaultState = () => {
  return {
    limit: 50,
    offset: 0,
    posts: [],
    isLoadingPosts: false,
    scrollPositions: [],
    surveyStep: "scroll",
    surveyStepDuration: 60,
    showPrompt: true,
    playStimulus: false,
    videoSelected: "", // Video ID
    videoSelectedIndex: -1,
    selectedPost: null,
  };
};

const state = defaultState();

const getters = {
  getPosts: (state) => state.posts,
  getPostIndx: (state) => state.posts[state.videoSelectedIndex],
  getSurveyStep: (state) => state.surveyStep,
  getSurveyStepDuration: (state) => state.surveyStepDuration,
  getShowPrompt: (state) => state.showPrompt,
  getPLayStimulus: (state) => state.playStimulus,
  getVideoSelected: (state) => state.videoSelected,
  getVideoSelected_index: (state) => state.videoSelectedIndex,
  getSelectedPost: (state) => state.selectedPost,
};

const mutations = {
  RESET_STATE: (state) => {
    const s = defaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_OFFSET: (state, offset) => (state.offset = offset),
  ADD_POSTS: (state, posts) => state.posts.push(...posts),
  SET_IS_LOADING_POSTS: (state, loading) => (state.isLoadingPosts = loading),
  SET_SHOW_PROMPT: (state, showPrompt) => (state.showPrompt = showPrompt),
  SET_SURVEY_STEP: (state, surveyStep) => (state.surveyStep = surveyStep),
  SET_PLAY_STIMULUS: (state, playStimulus) =>
    (state.playStimulus = playStimulus),
  SET_VIDEO_SELECTED: (state, videoID) => (state.videoSelected = videoID),
  SET_VIDEO_SELECTED_INDEX: (state, index) =>
    (state.videoSelectedIndex = index),
  SET_SELECTED_POST: (state, post) => (state.selectedPost = post),
};

const actions = {
  getPosts({ state, commit, rootGetters }) {
    // prevent duplicate requests from infinite scrolling
    if (state.isLoadingPosts) return;
    commit("SET_IS_LOADING_POSTS", true);
    SocialsService.retrievePosts({
      env: rootGetters["settings/getEnvironment"],
      limit: state.limit,
      skip: state.offset,
      source: rootGetters["stimulus/getViewer"],
    })
      .then((posts) => {
        // customize posts as needed for each paltform
        let customPosts = posts;
        if (rootGetters["stimulus/getViewer"] === "youtube") {
          customPosts = posts.map((post) => {
            post.body = JSON.parse(post.body);
            post.body.subscribers =
              Math.floor(Math.random() * (998 - 100 + 1)) + 100;

            post.body.isViewed = false;
            post.body.isLiked = false;
            post.body.comments = []; // TODO maybe fetch commnets
            return post;
          });
        }
        console.log("AddingPosts: ", customPosts);
        commit("ADD_POSTS", customPosts);
        commit("SET_OFFSET", state.posts.length);
      })
      .catch((error) => {
        return Promise.reject(error);
      })
      .finally(() => {
        commit("SET_IS_LOADING_POSTS", false);
      });
  },
};


export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state,
  getters,
  mutations,
  actions,
};
