import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
// import Cookies from "js-cookie";

// Import different store modules here
import stimulus from "./store/stimulus";
import settings from "./store/settings";
import loader from "./store/loader";
import utilities from "./store/utilities";
import facebook from "./store/facebook";
import instagram from "./store/instagram";
import youtube from "./store/youtube";
import tiktok from "./store/tiktok";

Vue.use(Vuex);

// const vuexCookie = new VuexPersistence({
//   restoreState: (key, storage) => Cookies.getJSON(key),
//   saveState: (key, state, storage) => {
//     Cookies.set(key, state);
//   },
//   modules: ["stimulus"]
// });

const vuexSecure = new VuexPersistence({
  storage: window.localStorage,
  // only use persistence to below modules
  reducer: (state) => ({
    settings: state.settings,
    stimulus: state.stimulus,
    loader: state.loader,
    facebook: state.facebook,
    instagram: state.instagram,
    youtube: state.youtube,
    tiktok: state.tiktok,
    // utilities: state.utilities
  }),
});

const storeModules = {
  utilities,
  settings,
  stimulus,
  loader,
  facebook,
  instagram,
  youtube,
  tiktok,
};

export default new Vuex.Store({
  modules: storeModules,
  strict: process.env.NODE_ENV !== "production",
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  plugins: [vuexSecure.plugin],
});
