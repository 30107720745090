import swaggerClient from "../plugins/swagger";

export default {
  retrieveStimulus(parameters) {
    return swaggerClient.emotivaClient
      .then(
        client => {
          return client.apis.respondents.retrieveStimulus(
            parameters,
            swaggerClient.buildRequest(),
            {}
          );
        },
        error => {
          return Promise.reject(error);
        }
      )
      .then(response => {
        return response.body;
      });
  },

  updateProgress(stimulusToken, payload) {
    return swaggerClient.emotivaClient
      .then(
        client => {
          return client.apis.respondents.progressTick(
            {
              stimulusToken
            },
            swaggerClient.buildRequest(payload),
            {}
          );
        },
        error => {
          return Promise.reject(error);
        }
      )
      .then(response => {
        return response.body;
      })
      .catch(reason => {
        console.log("ERROR: ", reason);
      });
  },

  uploadImages(stimulusToken, respondentId, samples) {
    return swaggerClient.emotivaClient
      .then((client) => {
        return client.apis.respondents.uploadFaces(
          {
            stimulusToken,
          },
          swaggerClient.buildRequest({
            respondentId,
            samples,
          }),
          {}
        );
      })
      .catch((reason) => {
        console.log("ERROR: ", reason);
      });
  },

  uploadImagesV2(stimulusToken, respondentId, samples, phase1) {
    return swaggerClient.emotivaClient
      .then((client) => {
        const data = {};
        data.respondentId = respondentId;
        data.phase1 = phase1;
        samples.forEach((sample, index) => {
          Object.entries(sample).forEach(
            ([key, value]) => (data[`samples[${index}][${key}]`] = value)
          );
        });
        return client.apis.respondents.uploadFacesV2(
          {
            stimulusToken,
          },
          swaggerClient.buildRequest(data),
          {}
        );
      })
      .catch((reason) => {
        console.log("ERROR: ", reason);
      });
  },

  uploadAnswers(stimulusToken, payload) {
    return swaggerClient.emotivaClient
      .then(client => {
        return client.apis.respondents.uploadAnswers(
          {
            stimulusToken
          },
          swaggerClient.buildRequest(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(reason => {
        console.log("ERROR: ", reason);
      });
  }
};
